import React from 'react'
import showdown from 'showdown'
const converter = new showdown.Converter()

export default ({ html, include_form, full_width }) => (
  <article className="post container pb-5">
    <div className="row ">
      <div
        className={`display-2 col-12 mb-5 ${include_form && 'col-lg-6'}`}
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(html) }}
      />
      {include_form && (
        <div className="col-12 col-lg-6">
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            className="mb-5"
          >
            <div className="font-weight-bold">
              <div className="form-group mb-4">
                <label htmlFor="name">What's your Name?</label>
                <input
                  className="form-control bg-dark"
                  id="name"
                  type="text"
                  name="name"
                />
              </div>
              <div className="form-group mb-4">
                <label htmlFor="email">What's your Email?</label>
                <input
                  className="form-control bg-dark"
                  type="email"
                  name="email"
                  id="email"
                />
              </div>
              <div className="form-group mb-4">
                <label htmlFor="message">What do you want to say?</label>
                <textarea
                  className="form-control bg-dark"
                  rows="3"
                  id="message"
                  name="message"
                ></textarea>
              </div>
              <div className="form-group mt-5">
                <button className="btn btn-primary" type="submit">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  </article>
)
